import Image from 'next/image'

import { Container } from '@/components/Container'
import backgroundImage from '@/images/background-faqs.jpg'

const faqs = [
    [
        {
            question: 'Hoe kan mijn onderwijsinstelling gebruik maken van Bijlesplein?',
            answer:
                'Wat leuk dat jullie willen gaan werken met Bijlesplein. Je kunt ons bellen op 073 203 2572 of mailen op info@bijlesplein.nl.',
        },
        {
            question: 'Is Bijlesplein AVG-proof?',
            answer:
                'Zeker weten. Veiligheid van de data en de verwerking hiervan is heel belangrijk. Een verwerkersovereenkomst is onderdeel van onze procedure.',
        },
    ],
    [
        {
            question: 'Hoe werkt het inloggen met een onderwijsinstelling-account?',
            answer:
                'Ons systeem kan worden gekoppeld met de Microsoft Azure of Google Workspace for Education omgeving van jouw onderwijsinstelling.',
        },
        {
            question: 'Is Bijlesplein veilig (SSO)?',
            answer:
                'Ons platform is uiterst veilig en kan alleen bij de data die écht van belang is. Zo is de veiligheid van de gebruikersaccounts gewaarborgd.',
        },
    ],
    [
        {
            question: 'Kan Bijlesplein in onze huisstijl?',
            answer: 'Zeker. En via het beheerpaneel voor leidinggevenden kun je dat ook nog gemakkelijk zelf in te stellen. Dit komt uitgebreid aan bod tijdens de onboarding.',
        },
        {
            question: 'Wie beheert het systeem voor mijn onderwijsinstelling?',
            answer: 'Je kunt zelf instellen wie het systeem beheert. Dit kan een medewerker of een bevoegde leerling of student zijn. Deze persoon kan vervolgens vakken en beoordelingen goed- of afkeuren en meer.',
        },
    ],
]

export function Faqs() {
    return (
        <section
            id="faq"
            aria-labelledby="faq-title"
            className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
        >
            <Image
                className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"
                src={backgroundImage}
                alt=""
                width={1558}
                height={946}
                unoptimized
            />
            <Container className="relative">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2
                        id="faq-title"
                        className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
                    >
                        Veelgestelde vragen
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-slate-700">
                        Kun je geen antwoord vinden op je vraag? Neem dan gerust
                        contact op met ons support team.
                    </p>
                </div>
                <ul
                    role="list"
                    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
                >
                    {faqs.map((column, columnIndex) => (
                        <li key={columnIndex}>
                            <ul role="list" className="flex flex-col gap-y-8">
                                {column.map((faq, faqIndex) => (
                                    <li key={faqIndex}>
                                        <h3 className="font-display text-lg leading-7 text-slate-900">
                                            {faq.question}
                                        </h3>
                                        <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </Container>
        </section>
    )
}
