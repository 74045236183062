import Image from 'next/image'

import {Button} from '@/components/Button'
import {Container} from '@/components/Container'
import logoFritsPhilips from '@/images/logos/fritsPhilips.png'
import logoHuygens from '@/images/logos/huygens.png'
import logoMencia from '@/images/logos/mencia.svg'
import logoNewman from '@/images/logos/newman.png'
import logoUrsula from '@/images/logos/ursula.png'
import Link from "next/link";
import happyTutoringImage from "@/images/happy-tutoring.jpg"
import wave from "@/images/wave.png"


export function Hero() {
    return (
        <>
            <div className="relative h-60 sm:h-[23rem] overflow-hidden">
                <Image
                    src={happyTutoringImage}
                    alt="Main image"
                    className="h-full w-full object-cover object-center"
                />
                <Image
                    src={wave}
                    alt="Wave"
                    className="h-24 w-full absolute -bottom-10"
                />
            </div>
            <Container className="pt-5 pb-16 text-center lg:pt-10">
                <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
                <span className="relative whitespace-nowrap text-blue-600 text-5xl sm:text-7xl">
          <svg
              aria-hidden="true"
              viewBox="0 0 418 42"
              className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
              preserveAspectRatio="none"
          >
            <path
                d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"/>
          </svg>
          <span className="relative">Bijlesplein</span>
        </span>{' '}
                    maakt het organiseren van bijles voor en door eigen leerlingen of studenten makkelijk
                </h1>
                <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
                    Onder regie van jouw eigen onderwijsinstelling komen vraag en aanbod van bijles samen.
                    Overzichtelijk
                    voor leerlingen en studenten, minder geregel voor de school.
                </p>
                <div className="mt-10 flex justify-center gap-x-6">
                    <Button contact>Neem contact op</Button>
                    <Button
                        href="#waarom-bijlesplein"
                        variant="outline"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="h-5 w-5 flex-none text-blue-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"/>
                        </svg>
                        <span className="ml-3">Waarom Bijlesplein?</span>
                    </Button>
                </div>
                <div className="mt-36 lg:mt-44">
                    <p className="font-display text-base text-slate-900">
                        Gebruikt door deze onderwijsinstellingen
                    </p>
                    <ul
                        role="list"
                        className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
                    >
                        {[
                            [
                                {name: 'Frits Philips', logo: logoFritsPhilips, url: 'https://fritsphilips.eu'},
                                {name: 'Christiaan Huygens', logo: logoHuygens, url: 'https://huygenslyceum.nl'},
                                {name: 'Newmancollege', logo: logoNewman, url: 'https://newmancollege.nl'},
                                {name: 'Mencia de Mendoza', logo: logoMencia, url: 'https://mencia.nl'},
                                // {name: 'Sint Ursula', logo: logoUrsula, url: 'https://ursula.nl'},
                            ],
                        ].map((group, groupIndex) => (
                            <li key={groupIndex}>
                                <ul
                                    role="list"
                                    className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                                >
                                    {group.map((company) => (
                                        <li key={company.name} className="flex">
                                            <Link href={company.url}>
                                                <Image src={company.logo} alt={company.name} className="h-10 w-auto"
                                                       unoptimized/>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            </Container>
        </>
    )
}
